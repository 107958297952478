'use client';

import { Flex, Text } from '@chakra-ui/react';
import { FirebaseError } from 'firebase/app';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { signIn as signInByNextAuth, useSession } from 'next-auth/react';
import { useRouter, useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { trackAmplitudeEvent } from '@/amplitude/trackAmplitudeEvent';
import { useTranslation } from '@/app/i18n/client';
import { AuthInput } from '@/components/common/AuthInput/AuthInput';
import { RectButton } from '@/components/common/RectButton/RectButton';
import { Paths } from '@/constants/path';
import { EMAIL_PATTERN } from '@/utils/emailPattern';
import { auth } from '@/utils/firebase/client';
import { getFirebaseErrorMessage } from '@/utils/firebase/firebase_error_message';
import { createPath } from '@/utils/path';

interface Props {
  lng: string;
}

export const LoginForm = ({ lng }: Props): JSX.Element => {
  const { t } = useTranslation('auth', {}, lng);
  const session = useSession();
  const router = useRouter();
  const searchParams = useSearchParams();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const matchEmail = useMemo(() => {
    const matchArr = email.match(EMAIL_PATTERN);
    return matchArr?.length;
  }, [email]);

  const redirectUrl = useMemo(() => {
    const redirect = searchParams?.get('redirect');
    return typeof redirect === 'string'
      ? redirect
      : createPath({ path: Paths.v2 });
  }, [searchParams]);

  const handleLogin = useCallback(async () => {
    setErrorText('');
    setIsLoading(true);

    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      trackAmplitudeEvent('LoginSubmitButtonClicked');
      const idToken = await user.getIdToken();
      await signInByNextAuth('credentials', {
        idToken,
        refreshToken: user.refreshToken,
        host: `${window.location.protocol}//${window.location.host}`,
        callbackUrl: redirectUrl,
      });
    } catch (error) {
      setErrorText(
        error instanceof FirebaseError
          ? getFirebaseErrorMessage(error)
          : 'ログインに失敗しました'
      );
      setIsLoading(false);
    }
  }, [email, password, redirectUrl]);

  useEffect(() => {
    if (
      session.status === 'authenticated' &&
      session.data?.error === undefined
    ) {
      router.replace(redirectUrl);
    }
  }, [redirectUrl, router, session.data?.error, session.status]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleLogin();
      }}
    >
      <Flex flexDirection="column" gap="16px">
        <AuthInput
          placeholder={t('email', 'メールアドレス')}
          focusPlaceholder="hello@capturex.world"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          type="email"
          autoComplete="email"
          dataTestId="login-email-input"
        />
        <AuthInput
          placeholder={t('password', 'パスワード')}
          focusPlaceholder="********"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          type="password"
          autoComplete="off"
          dataTestId="login-password-input"
        />
        <RectButton
          label={t('login.submit', 'ログインする')}
          color="text.white"
          type="submit"
          border="none"
          background="bg.black"
          isDisabled={!email || !matchEmail || !password}
          isLoading={isLoading}
          dataTestId="login-button"
        />
        {errorText && (
          <Text
            mt="8px"
            color="red"
            fontSize="xs"
            data-testid="login-error-message"
          >
            {errorText}
          </Text>
        )}
      </Flex>
    </form>
  );
};
