import React from 'react';

function IconEyeShow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#9CA3AD"
        d="M10 6.114a4.286 4.286 0 100 8.572 4.286 4.286 0 000-8.572zm0-2.142a9.687 9.687 0 018.737 5.557c.255.553.255 1.19 0 1.743a9.646 9.646 0 01-17.473 0 2.076 2.076 0 010-1.743A9.685 9.685 0 0110 3.972M10 2.9A10.715 10.715 0 00.294 9.075a3.142 3.142 0 000 2.651 10.717 10.717 0 0019.412 0 3.142 3.142 0 000-2.651A10.715 10.715 0 0010 2.9z"
      ></path>
    </svg>
  );
}

export const SvgIconEyeShow = React.memo(IconEyeShow);
