'use client';

import Link from 'next/link';
import { PropsWithChildren } from 'react';

import { trackAmplitudeEvent } from '@/amplitude/trackAmplitudeEvent';
import { Paths } from '@/constants/path';
import { createPath } from '@/utils/path';

type Props = PropsWithChildren;

export const ToPasswordResetPageLink = ({ children }: Props): JSX.Element => {
  return (
    <Link
      href={createPath({ path: Paths.resetPassword })}
      onClick={() => trackAmplitudeEvent('ToPasswordResetPageButtonClicked')}
      prefetch={false}
    >
      {children}
    </Link>
  );
};
