import { FirebaseError } from 'firebase/app';
export const getFirebaseErrorMessage = (err: FirebaseError) => {
  switch (err.code) {
    case 'auth/wrong-password':
      return '正しいパスワードを入力してください。';
    case 'auth/user-not-found':
      return 'ユーザーが見つかりません。登録してください。';
    case 'auth/requires-recent-login':
      return 'ログインし直してください。';
    case 'auth/too-many-requests':
      return 'しばらく時間をおいてから実行してください。';

    default:
      return '不明なエラーです。';
  }
};
