'use client';

import {
  Box,
  forwardRef,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { HTMLInputTypeAttribute, useMemo, useState } from 'react';

import { SvgIconEyeHide } from '@/components/assets/IconEyeHide';
import { SvgIconEyeShow } from '@/components/assets/IconEyeShow';

type AuthInputProps = InputProps & {
  placeholder: string;
  focusPlaceholder?: string;
  readOnly?: boolean;
  type?: HTMLInputTypeAttribute;
  dataTestId?: string;
  errorMsg?: string;
};
const AuthInput = forwardRef<AuthInputProps, 'input'>(
  (
    {
      placeholder,
      focusPlaceholder,
      readOnly,
      type,
      autoComplete = 'off',
      dataTestId,
      errorMsg,
      onFocus,
      onBlur,
      ...props
    },
    ref
  ) => {
    const [isShow, setIsShow] = useState(false);
    const inputType = useMemo(() => {
      if (type === 'password') {
        return isShow ? 'text' : 'password';
      }
      return type;
    }, [isShow, type]);

    return (
      <Box w="full">
        <InputGroup h="56px">
          <Input
            {...props}
            ref={ref}
            border="none"
            borderRadius="16px"
            color="text.gray"
            bg="bg.lightGray"
            fontWeight="medium"
            fontSize="sm"
            height="auto"
            p="10px 16px"
            placeholder={placeholder}
            _placeholder={{
              color: 'text.negative',
              height: '100%',
              paddingTop: '8px',
              fontSize: 'sm',
            }}
            onFocus={(e) => {
              onFocus?.(e);
              e.target.style.border = '1px solid #EBEBEE';
              e.target.style.backgroundColor = '#FFF';
              e.target.style.color = '#656D7E';
              e.target.placeholder = focusPlaceholder ?? placeholder;
              e.target.style.boxShadow = '0 4px 16px rgba(0, 0, 0, 0.16)';
            }}
            onBlur={(e) => {
              onBlur?.(e);
              e.target.style.border = 'none';
              e.target.style.backgroundColor = '#F3F4F5';
              e.target.placeholder = placeholder;
              e.target.style.boxShadow = 'none';
            }}
            type={inputType ?? 'text'}
            readOnly={readOnly}
            style={
              readOnly
                ? {
                    pointerEvents: 'none',
                    userSelect: 'none',
                  }
                : {}
            }
            autoComplete={autoComplete}
            data-testid={dataTestId}
          />
          {type === 'password' && (
            <InputRightElement h="56px" display="flex" alignItems="center">
              {isShow ? (
                <Box cursor="pointer" onClick={() => setIsShow(false)}>
                  <SvgIconEyeShow />
                </Box>
              ) : (
                <Box cursor="pointer" onClick={() => setIsShow(true)}>
                  <SvgIconEyeHide />
                </Box>
              )}
            </InputRightElement>
          )}
        </InputGroup>
        {errorMsg && (
          <Text fontSize="xs" color="red" fontWeight="medium" mt="8px">
            {errorMsg}
          </Text>
        )}
      </Box>
    );
  }
);

export { AuthInput };
export type { AuthInputProps };
