import { Button, ButtonProps, Flex, Spinner, Text } from '@chakra-ui/react';
import { FC } from 'react';

type RectButtonProps = ButtonProps & {
  label?: string;
  color: string;
  background: string;
  dataTestId?: string;
  href?: string;
};

const RectButton: FC<RectButtonProps> = ({
  label,
  color,
  background,
  isDisabled,
  isLoading,
  border,
  borderColor,
  dataTestId,
  href,
  ...props
}) => {
  return (
    <Button
      data-testid={dataTestId}
      background={isDisabled ? 'bg.lightGray' : background}
      borderRadius="16px"
      flex={1}
      w="full"
      p="10px 16px"
      border={border ?? '1px solid'}
      borderColor={borderColor ?? background}
      isDisabled={isDisabled}
      isLoading={isLoading}
      href={href}
      h="56px"
      maxH="56px"
      {...props}
    >
      <Flex alignItems="center" justifyContent="center">
        {isLoading ? (
          <Spinner color={color} />
        ) : (
          <Text
            color={isDisabled ? 'text.disabledGray' : color}
            fontSize="md"
            fontWeight="medium"
            whiteSpace="nowrap"
            mx="2"
            lineHeight="36px"
          >
            {label}
          </Text>
        )}
      </Flex>
    </Button>
  );
};

export { RectButton };
export type { RectButtonProps };
