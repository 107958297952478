import React from 'react';

function IconEyeHide() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#clip0_1_7474)">
        <path
          fill="#9CA3AD"
          d="M19.706 11.714A10.685 10.685 0 015.69 16.97l.836-.836a9.481 9.481 0 003.474.682 9.686 9.686 0 008.736-5.558 2.076 2.076 0 000-1.742 9.534 9.534 0 00-2.424-3.168l.772-.772a10.732 10.732 0 012.622 3.485c.392.84.392 1.811 0 2.652zM10 14.674a4.287 4.287 0 003.946-5.96l-5.62 5.62a4.27 4.27 0 001.674.34zm-8.902 3.48a.804.804 0 010-1.137l1.816-1.816a10.746 10.746 0 01-2.62-3.487 3.142 3.142 0 010-2.652A10.686 10.686 0 0114.31 3.804l2.32-2.319a.803.803 0 111.136 1.137L2.234 18.154a.803.803 0 01-1.136 0zm2.579-3.716l2.377-2.377a4.287 4.287 0 015.62-5.62l1.803-1.803A9.558 9.558 0 0010 3.959a9.686 9.686 0 00-8.736 5.557 2.076 2.076 0 000 1.743 9.612 9.612 0 002.413 3.18z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1_7474">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export const SvgIconEyeHide = React.memo(IconEyeHide);
