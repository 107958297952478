import * as Amplitude from '@amplitude/analytics-browser';

import { TagName } from '@/utils/tags';

// eslint-disable-next-line no-restricted-imports
import packageInfo from '../../package.json';

export const trackAmplitudeEvent = (eventName: TagName) => {
  Amplitude.logEvent(eventName, { version: packageInfo.version });
};
